<template>
  <div class="bg--white">
    <header class="header">
      <div
        id="edito"
        class="container"
      >
        <LazySectionEdito />
      </div>
    </header>
    <section class="section-padding">
      <LazyHMarquee
        v-slot="{element}"
        :elements="pressArticles"
      >
        <template v-if="element.link">
          <LazyNuxtLink
            :to="element.link"
            target="_blank"
          >
            <NuxtImg
              :src="element.thumbnail_url"
              :alt="`logo ${element.title}`"
              draggable="false"
              class="carousel__img"
              loading="lazy"
              format="webp"
              quality="80"
              height="46"
              width="200"
              fit="contain"
              :modifiers="{ b: 'white' }"
            />
          </LazyNuxtLink>
        </template>
        <template v-else>
          <NuxtImg
            :src="element.thumbnail_url"
            :alt="`logo ${element.title}`"
            draggable="false"
            class="carousel__img"
            loading="lazy"
            format="webp"
            quality="80"
            height="46"
            width="150"
            fit="contain"
            :modifiers="{ b: 'white' }"
          />
        </template>
      </LazyHMarquee>
    </section>

    <div class="section-rounded section-rounded--grey">
      <LazySectionNumerotedSlider
        v-bind="welcomeSectionProps"
      />
      <LazySectionNumberSlider
        v-bind="halfCircleAndNumbersProps"
        class="section-padding--top"
      >
        <template #after>
          <div class="row section-padding--top align-items-center">
            <div class="col-12 col-lg-4 mb-4 mb-lg-0">
              <NuxtImg
                format="webp"
                loading="lazy"
                width="690"
                height="737"
                sizes="377px lg:290px 377px xxl:377px"
                src="/img/home/bien-estimer.png"
                alt=""
                quality="80"
              />
            </div>
            <div class="col-12 col-lg-7 offset-lg-1">
              <LazyHH3 class="mb-4">
                Bien estimer pour bien vendre
              </LazyHH3>
              <p>
                Parce que nous savons qu’une bonne vente commence par un bien proposé au juste prix du marché, l’estimation est un sujet clé chez homeloop !<br><br>
                Convaincus que la technologie se doit d’être au service de l’humain, nous avons allié ces deux forces pour créer notre outil d’estimation maison. Alimenté par des bases de données privées, publiques et nos données propres, notre outil est contrôlé et ajusté par une équipe de professionnels, spécialiste de l’immobilier. Ses analyses sont aussi couplées à la connaissance local de nos experts terrain.<br><br>
                Voilà pourquoi notre estimateur est l’un des plus pointus du marché !
              </p>
              <LazyHButton
                color="purple"
                class="mt-4"
                tag="link"
                to="/qui-sommes-nous"
                block-on-mobile
              >
                En savoir plus sur homeloop
              </LazyHButton>
            </div>
          </div>
        </template>
      </LazySectionNumberSlider>
    </div>

    <div class="section-rounded section-rounded--dark">
      <LazySectionTestimonialSlider
        v-bind="testimonialsSectionProps"
      />
    </div>

    <div class="section-rounded section-rounded--white">
      <LazySectionMultiDropdownWithIcon v-bind="whySectionProps" />

      <section class="section-padding--top">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 mb-4 mb-lg-0">
              <LazyHSectionTitle
                first-part="Pas pressé de vendre&nbsp;?<br>"
                second-part="Choisissez la vente accompagnée"
                last-part="."
                class="mb-5"
              />
              <p class="mb-3 text--night-blue text--intro">
                Vous appréciez la sécurité de l’achat immobilier instantané et n’avez pas d’urgence à vendre&nbsp;? Optez pour la vente accompagnée&nbsp;!
              </p>
              <p>Faites confiance à homeloop pour vous trouver rapidement un acheteur pour votre bien. Vendez vite et au meilleur prix grâce à notre outil d’estimation, parmi les plus pointus du secteur, qui vous permet de positionner votre bien au juste prix du marché. Ne prenez aucun risque : nous vous présentons uniquement des acquéreurs dont nous avons vérifié avec exactitude la capacité de financement.</p>
              <LazyHButton
                color="purple"
                class="mt-4"
                tag="link"
                to="/booking/charaf.idali/call"
                block-on-mobile
              >
                Prendre un rendez-vous
              </LazyHButton>
            </div>
            <div class="col-lg-5 offset-lg-1">
              <NuxtImg
                format="webp"
                loading="lazy"
                width="459px"
                height="542px"
                quality="80"
                sizes="459px lg:290px xl:459px"
                src="/img/home/pas-presse.jpeg"
                alt=""
              />
            </div>
          </div>
          <div class="row section-padding--top">
            <div class="col-12 mb-4 mb-lg-0">
              <LazyHH4 class="mb-3">
                En 5 étapes, votre bien immobilier est vendu
              </LazyHH4>
              <p>Un processus rapide pour vous assurer de vous concentrer sur l’essentiel.</p>
            </div>
            <div class="col-12">
              <HSlider
                :items="mandatSliderItems"
                :slides-per-view="{ md: 2, lg: 2.5, xl: 3 }"
              >
                <template #slide="{item, index}">
                  <LazyHNumerotedCard
                    :number="index + 1"
                    v-bind="item"
                    bordered
                  />
                </template>
              </HSlider>
            </div>
          </div>
        </div>
      </section>

      <LazySectionServicePromotion
        class="section-padding--top"
        v-bind="servicePromotionProps"
      />
    </div>

    <div class="section-rounded section-rounded--grey">
      <section>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 mb-4 mb-lg-0">
              <LazyHSectionTitle
                first-part="Vous avez un projet d’achat immobilier ? "
                second-part="Découvrez nos biens à vendre"
                last-part="."
                class="mb-5"
              />
              <NuxtImg
                format="webp"
                loading="lazy"
                quality="80"
                width="362px"
                height="236px"
                src="/img/image-8.jpeg"
                alt=""
              />
            </div>
            <div class="col-lg-5 offset-lg-1">
              <p>
                Vous cherchez votre nouveau nid douillet où poser vos valises&nbsp;? Votre futur chez vous est probablement chez nous&nbsp;! Nous savons qu’un achat immobilier est une étape clé dans la vie et répond bien souvent à un nouveau projet de vie, qu’il soit heureux comme une naissance, ou malheureux, comme un divorce.<br><br>

                C’est pourquoi nous vous proposons les plus beaux biens au meilleur prix. Un investissement immobilier ne se faisant pas à la légère, notre équipe d’experts vous accompagne et simplifie au maximum votre processus d'acquisition. Chez homeloop, nous pensons qu’acheter un bien immobilier devrait toujours être une belle aventure. Et comme nous n’aimons que les bonnes surprises, il n’est pas rare que nous ayons déjà procédé à des travaux d'embellissement ou de rénovation.
              </p>
              <LazyHButton
                color="purple"
                class="mt-4"
                tag="link"
                to="/acheter/annonces/region"
                block-on-mobile
              >
                Découvrir les biens à acheter
              </LazyHButton>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding--top">
        <div class="container">
          <div class="row align-items-center mb-5">
            <div class="col-12 col-lg-6">
              <LazyHSectionTitle
                first-part="Devenez propriétaire<br>"
                second-part="en toute sérénité"
                last-part="."
              />
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-end d-none d-lg-flex">
              <LazyHButton
                color="purple"
                outlined
                tag="link"
                to="/acheter/annonces/region"
              >
                Voir tous nos biens
              </LazyHButton>
            </div>
          </div>
          <div class="row">
            <LazyHSlider
              :items="sortedPropertiesByCreationDate.slice(0, 4)"
              :slides-per-view="{ md: 2.5, lg: 3.2, xl: 4 }"
              swiper-slide-class="col-12 col-lg-4"
              hide-pagination
            >
              <template #slide="{item}">
                <LazyHPropertyCard :property="item" />
              </template>
            </LazyHSlider>
          </div>
          <div class="row d-lg-none mt-4">
            <div class="col-12">
              <LazyHButton
                color="purple"
                outlined
                block-on-mobile
              >
                Voir tous nos biens
              </LazyHButton>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="section-rounded section-rounded--dark">
      <section>
        <div class="container">
          <div class="row align-items-center mb-5">
            <div class="col-lg-7">
              <LazyHSectionTitle
                first-part="homeloop vous accompagne<br>"
                second-part="partout en France"
                last-part="."
              />
            </div>
          </div>
          <div class="row">
            <LazyHSlider
              :items="cityCards"
              :slides-per-view="{ md: 2, lg: 2.5, xl: 3.5 }"
            >
              <template #slide="{item}">
                <LazyHCityCard
                  v-bind="item"
                />
              </template>
            </LazyHSlider>
          </div>
        </div>
      </section>
    </div>

    <div class="section-rounded section-rounded--white">
      <section>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-lg-6 mb-4 mb-lg-0">
              <NuxtImg
                format="webp"
                loading="lazy"
                width="603"
                height="402"
                fit="outside"
                sizes="xs:510px sm:690px lg:450px xl:603px xxl:603px"
                src="/img/thierry-vanessa-benedic.jpeg"
                alt="Thierry et Vanessa Benedic"
                quality="80"
              />
            </div>

            <div class="col-12 col-lg-5 offset-lg-1">
              <LazyHH3 class="mb-4">
                La force d’un grand groupe
              </LazyHH3>
              <p>
                Depuis 2022, homeloop a rejoint le groupe familial Benedic, nous permettant ainsi de bénéficier de la force humaine et de l’expertise pointue d’un groupe lorrain incontournable depuis 1966. Bénéficiez d’un partenaire de confiance et d’une connaissance parfaite des marchés immobiliers locaux.
              </p>
              <LazyHButton
                color="purple"
                class="mt-5"
                outlined
                block-on-mobile
                tag="link"
                to="https://www.benedicsa.com/"
                target="_blank"
              >
                Découvrir le groupe Benedic
              </LazyHButton>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="section-rounded section-rounded--grey">
      <section>
        <div class="container">
          <div class="row align-items-center justify-content-center text-center">
            <div class="col-auto">
              <LazyHSectionTitle
                first-part="Vendre son bien immobilier<br>"
                second-part="dans les meilleures conditions"
                last-part="."
              />
            </div>
          </div>
          <div class="row align-items-center section-padding">
            <div class="col-12 col-lg-5 order-1 order-md-0 mt-4 mb-lg-0">
              <LazyHH3 class="mb-4">
                L’innovation au service de nos clients pour ambition
              </LazyHH3>
              <p>
                Depuis sa création, homeloop casse les codes de l’immobilier en simplifiant la transaction immobilière. Avec nous, oubliez les processus de transaction longs, angoissants et coûteux.<br><br>

                <span class="text--purple">Découvrez l’immobilier sans détour</span>
              </p>
            </div>
            <div class="col-12 col-lg-5 offset-lg-2 order-0 order-md-1">
              <NuxtImg
                format="webp"
                loading="lazy"
                quality="80"
                width="497"
                height="373"
                sizes="400px sm:720px md:500px"
                fit="outside"
                src="/img/home/innovation.jpeg"
                alt=""
                class="d-block mx-auto"
              />
            </div>
          </div>
          <div class="row align-items-center section-padding--bottom">
            <div class="col-12 col-lg-5 mb-4 mb-lg-0">
              <NuxtImg
                format="webp"
                loading="lazy"
                quality="80"
                width="497"
                height="373"
                sizes="400px sm:720px md:500px"
                fit="outside"
                src="/img/home/expertise.jpeg"
                alt=""
                class="d-block mx-auto"
              />
            </div>
            <div class="col-12 col-lg-5 offset-lg-2">
              <LazyHH3 class="mb-4">
                L’expertise et l’excellence d’une équipe dévouée
              </LazyHH3>
              <p>
                Notre équipe se met à votre service pour vous accompagner dans la réalisation de vos projets immobiliers. Grâce à notre outil d’estimation et à notre équipe d’experts, nous évaluons avec précision le prix de marché de votre bien immobilier.<br><br>

                <span class="text--purple">Vendre, oui, mais jamais sans accompagnement !</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <LazyHCard>
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6">
                    <LazyHH3
                      class="mb-4"
                      color="white"
                    >
                      Le saviez-vous ?
                    </LazyHH3>
                    <p>
                      En achetant un bien immobilier à homeloop, vous bénéficiez de garanties supplémentaires en raison de notre statut de professionnel.<br>
                      Ainsi, vous êtes assuré durant 2 ans contre les vices cachés de votre nouveau logement. Achetez en toute sérénité avec homeloop !
                    </p>
                    <LazyHButton
                      color="white"
                      class="mt-4"
                      outlined
                      tag="link"
                      to="/acheter/annonces"
                    >
                      Acheter un bien homeloop
                    </LazyHButton>
                  </div>
                  <div class="d-none d-lg-block col-12 col-lg-3 offset-lg-1">
                    <LazySvgHomepageIllu />
                  </div>
                </div>
              </LazyHCard>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="section-rounded section-rounded--white mb-0 pb-0">
      <LazySectionMultiDropdown
        v-bind="{...FAQProps, items: questions}"
        class="section-padding--bottom"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SvgLightning from '~/components/svg/Lightning.vue'
import SvgHeart from '~/components/svg/Heart.vue'
import SvgLock from '~/components/svg/Lock.vue'
import SvgSmiley from '~/components/svg/Smiley.vue'
import type { BranchModel, HSectionTitleProps } from '~/types'

const { api } = useFeathers()
const localePath = useLocalePath()

useHead({
  bodyAttrs: {
    class: 'v2023'
  },
  title: 'homeloop | Vendez votre appartement ou maison aujourd\'hui',
  meta: [
    { hid: 'description', name: 'description', content: 'homeloop s\'engage à acheter votre appartement ou maison. Recevez une offre d\'achat en 48 heures pour en finir avec les incertitudes' },
    { hid: 'og:description', property: 'og:description', content: 'homeloop s\'engage à acheter votre appartement ou maison. Recevez une offre d\'achat en 48 heures pour en finir avec les incertitudes' }
  ]
})

const faqService = api.service('faq')
const pressArticlesService = api.service('press-articles')
const branchesService = api.service('branches')

const promises = [
  useAsyncData('press-articles', async () => await pressArticlesService.find({ query: { listed_on_website: true } })),
  useAsyncData('faq', async () => {
    return await faqService.find({ query: { id: { $in: [14, 20, 4, 22, 23, 12] } } })
  }),
  useAsyncData('properties', async () => {
    const { data } = await useHFetch('/apimo/properties', { method: 'GET' })
    return data.value
  }),
  useAsyncData('branches', async () => await branchesService.find({ query: { listed_on_website: true, $limit: -1, $sort: { order: 1 } } }))
]

const [{ data: pressArticles }, { data: faq }, { data: properties }, { data: branches }] = await Promise.all(promises)
const sortedPropertiesByCreationDate = computed(() => properties.value.sort((a: { created_at: string | number | Date; }, b: { created_at: string | number | Date; }) => (new Date(b.created_at) as any) - (new Date(a.created_at) as any)))

const questions = computed(() => faq.value?.map(({ question, answer }: { question: string, answer: string }) => ({
  title: question,
  text: answer
})))

const cityCards = branches.value?.map((branch: BranchModel) => ({
  img: {
    src: branch.picture_url,
    alt: `photo de ${branch.name}`
  },
  title: branch.name,
  path: '/acheter/annonces/region/' + branch.slug?.trim()
}))

const welcomeSectionProps = ref({
  titleProps: {
    firstPart: 'Une transaction ',
    secondPart: 'rapide, efficace, sécurisée',
    lastPart: '.'
  },
  intro: 'En 4 étapes seulement, vous avez vendu !<br>Bienvenue chez homeloop.',
  items: [
    {
      title: 'Estimation instantanée',
      text: 'Renseignez quelques informations sur votre bien, obtenez une première estimation. Le tout, en moins de 5 minutes.'
    },
    {
      title: 'Expertise sur-mesure',
      text: 'Échangez sur votre projet immobilier avec un expert de votre secteur. Obtenez rapidement une pré-offre d’achat.'
    },
    {
      title: 'Visite de votre bien',
      text: 'Recevez votre expert chez vous pour une visite approfondie. L’assurance d’une offre fiable.'
    },
    {
      title: 'Offre ferme et définitive',
      text: 'Profitez d’une offre adaptée à votre projet et à vos contraintes. Simple comme homeloop !'
    }
  ],
  cta: {
    color: 'purple',
    text: 'Prendre un rendez-vous',
    tag: 'link',
    to: '/booking/charaf.idali/call'
  }
})

const halfCircleAndNumbersProps = ref({
  titleProps: {
    firstPart: 'Fini les compromis,<br>',
    secondPart: 'passez à l’action avec homeloop',
    lastPart: '.'
  },
  items: [
    {
      title: '48h',
      text: 'pour recevoir une offre d’achat'
    },
    {
      title: '1<sup>er</sup>',
      text: 'acheteur de biens immobiliers en France'
    },
    {
      title: '1500',
      text: 'transactions réalisées depuis 2017<br><i>soit 18/mois environ</i>'
    },
    {
      title: '+2000',
      text: 'Demandes d’offres d’achat chaque mois'
    },
    {
      title: '95%',
      text: 'de clients satisfaits'
    }
  ]
})

const whySectionProps = ref({
  titleProps: {
    firstPart: 'Pourquoi choisir l’achat<br>',
    secondPart: 'immobilier instantané ',
    lastPart: '?'
  },
  grid: 2,
  items: [
    {
      icon: { component: shallowRef(SvgLightning) },
      initialyOpen: true,
      title: 'C’est rapide',
      text: `L’achat immobilier instantané vous permet d’obtenir une offre d’achat ferme et définitive en 48h seulement, sans délai de rétractation, ni condition suspensive de financement !<br>
      La rapidité est également de mise en ce qui concerne vos fonds : le paiement se fait comptant.<br><br>

      Vendez vite, vivez en instantané !`
    },
    {
      icon: { component: shallowRef(SvgSmiley) },
      title: 'C’est simple',
      text: `Notre mission est de fluidifier les transactions et de faciliter les changements de vie immobilière. Ainsi, nous vous proposons un processus de vente simple, vous permettant d’échapper à certaines étapes administratives fastidieuses et superflues.<br><br>

      Gagnez du temps, faites simple !`
    },
    {
      icon: { component: shallowRef(SvgLock) },
      title: 'C’est sécurisé',
      text: `Clair et sans surprise : vous savez à qui vous vendez, combien vous vendez et quand !<br>
      Vous maitrisez votre vente du début à la fin, tout en profitant de l’accompagnement d’une équipe de professionnels experts de leur secteur : immobilier, juridique, travaux…<br><br>

      Vendez vite fait, bien fait !`
    },
    {
      icon: { component: shallowRef(SvgHeart) },
      title: 'C’est serein',
      text: `Oubliez les montagnes russes et la lenteur induites par les processus de vente traditionnelles : ses visites, négociations, inspections, démarches administratives et potentielles rétractations…<br>
      Vous avez désormais le temps de vous concentrer sur l’essentiel : votre futur projet.<br><br>

      Pas de déconvenue : vous avez déjà vendu !`
    }
  ],
  cta: {
    tag: 'link',
    text: 'Obtenir une offre',
    to: '#edito'
  }
})

const testimonialsSectionProps = ref({
  titleProps: {
    firstPart: 'Ils ont choisi l’achat instantané<br>',
    secondPart: 'et ils en sont ravis ',
    lastPart: '!'
  },
  items: [
    {
      name: 'Isa-Régis. I',
      date: 'Mai 2023',
      rating: 4.5,
      title: 'Nous sommes très satisfaits de notre relation avec homeloop',
      text: `Tout d'abord, très bon contact avec M Leduc, attentif, il a compris nos attentes. Très vite, nous avons eu une proposition pour l'achat de notre maison. Ensuite, mention spéciale pour Chloé qui a monté le dossier du compromis avec efficacité, toujours disponible&nbsp;!<br>
      La vente s'est bien déroulée avec une signature dans les temps.<br>
      Donc satisfait !`
    },
    {
      name: 'Françoise. S',
      date: 'Janvier 2023',
      rating: 4.5,
      title: 'Travailler avec homeloop est très efficace et "reposant"',
      text: `Ils anticipent, sont très réactifs lorsque nous avons une question.Ils ont acheté mon appartement au prix du marché en paiement comptant, donc compromis sans condition suspensive.<br>
      J'ai considéré que le fait qu'ils prennent tous les risques vaut largement les % qu'ils retiennent, compte tenu du fait qu'ils prennent complètement leur client en charge sur le plan de l'accompagnement.<br>
      Je recommande vivement`
    },
    {
      name: 'Isabelle. C',
      date: 'Janvier 2023',
      rating: 4.5,
      title: 'Merci',
      text: `Merci à homeloop de m'avoir permis de concrétiser mon projet d'achat d'un nouvel appartement, sans avoir besoin de passer par une banque et d'avoir pu me positionner en premier sur mon achat grâce au paiement comptant.<br>
      Je témoigne de leur efficacité, leur gentillesse, sympathie et patience, non négligeable dans un moment pareil : quand on vend et achète le même jour !<br>
      Merci à toute l'équipe`
    }
  ]
})

const mandatSliderItems = ref([
  {
    title: 'Nous estimons votre bien au juste prix du marché'
  },
  {
    title: 'Vous choisissez vos services et la mise en valeur de votre bien'
  },
  {
    title: 'Nous nous occupons de toute la commercialisation de votre bien'
  },
  {
    title: 'Vous signez le compromis de vente puis l’acte authentique chez le notaire.'
  },
  {
    title: 'Vous avez vendu !'
  }
])

const servicePromotionProps = {
  titleProps: {
    firstPart: 'Vendre son bien immobilier&nbsp;:<br>',
    secondPart: 'choisir la vente instantanée ou la vente accompagnée',
    lastPart: ' ?'
  } as HSectionTitleProps,
  intro: 'Peu importe ce que vous choisissez, <b>homeloop vous accompagne</b> et vous permet de trouver la solution idéale pour la vente de votre bien.',
  cards: [
    {
      color: 'purple',
      title: 'La vente instantanée avec homeloop',
      topText: 'Une offre ferme et définitive sans condition suspensive, ni délai de rétractation',
      items: [
        'Vous savez à quel prix vous vendez',
        'Vous choisissez quand vous vendez',
        'Vous êtes payé comptant'
      ],
      bottomText: 'Une vente ferme et sécurisée',
      cta: {
        tag: 'link',
        text: 'Vendre instantanément avec homeloop',
        to: '#edito'
      }
    },
    {
      color: 'pink',
      title: 'La vente accompagnée avec homeloop',
      topText: 'Vous vendez vite, au meilleur prix et à un acquéreur dont la capacité financière est vérifiée',
      items: [
        'Vous positionnez votre bien au juste prix du marché',
        'Vous êtes entouré de professionnels pour concrétiser votre vente'
      ],
      bottomText: 'Une vente sereine et efficace',
      cta: {
        tag: 'link',
        text: 'Je souhaite être accompagné',
        to: '/vente-accompagnee'
      }
    }
  ]
}

const FAQProps = ref({
  color: 'night-blue',
  titleProps: {
    firstPart: 'Vous avez des questions ?<br>',
    secondPart: 'Nous avons les réponses',
    lastPart: '.'
  },
  items: [],
  cta: {
    tag: 'link',
    text: 'Voir toutes les questions',
    to: localePath('/faq')
  }
})

</script>

<style lang="scss" scoped>
.header {
  padding-top: 2.19rem;
}

.carousel__img {
  width: 200px;
  height: 46px;
  object-fit: contain;
}

:deep(.marquee__content) {
  align-items: center;
}
</style>
